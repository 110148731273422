<script setup lang="ts">
const { isLoggedIn, logout, user } = useUser();

defineProps<{ label?: string }>();
const loginModalController = useModal();
const sideMenuController = useSideMenuModal();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const isAccountMenuOpen = ref(false);

async function invokeLogout() {
  await logout();
  isAccountMenuOpen.value = false;
}
</script>
<template>
  <div class="flex justify-center">
    <div class="my-account-area">
      <ClientOnly>
        <div v-show="!isLoggedIn">
          <NuxtLink
            to="/login"
            class="flex items-center gap-3 whitespace-nowrap bg-transparent text-base font-medium lg:gap-0"
            @click="sideMenuController.close"
          >
            <div
              class="text-dark hover:text-brand-primary i-custom-svg:account h-6 w-6"
            />
            <span class="sr-only">{{ $t('account.menu.signIn') }}</span>
            <span>{{ label }}</span>
          </NuxtLink>
        </div>
        <SharedModal :controller="loginModalController">
          <AccountLoginForm
            @close="loginModalController.close"
            @success="loginModalController.close"
          />
        </SharedModal>
        <div v-if="isLoggedIn">
          <div class="flex items-center lg:p-2">
            <NuxtLink
              to="/account"
              type="button"
              class="flex items-center gap-3 whitespace-nowrap bg-transparent text-base font-medium lg:gap-0"
              @click="sideMenuController.close"
            >
              <div
                class="text-dark hover:text-brand-primary i-custom-svg:account h-6 w-6"
              />
              <span class="block md:sr-only"
                >{{ $t('account.menu.hello') }}, {{ user?.firstName }}!</span
              >
            </NuxtLink>

            <!-- Profile dropdown -->
            <div class="relative">
              <div>
                <button
                  id="user-menu-button"
                  type="button"
                  class="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  aria-expanded="false"
                  aria-haspopup="true"
                  @click="isAccountMenuOpen = !isAccountMenuOpen"
                >
                  <span class="sr-only">{{ $t('account.menu.openMenu') }}</span>
                </button>
              </div>
              <Transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <div
                  :class="[isAccountMenuOpen ? 'block' : 'hidden']"
                  class="absolute right-0 top-2 z-20 mt-2 w-48 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabindex="-1"
                >
                  <div
                    class="border-b-1 border-dashed border-gray-400 px-2 py-2"
                  >
                    {{ $t('account.menu.hello') }}, {{ user?.firstName }}!
                  </div>
                  <NuxtLink
                    id="user-menu-item-1"
                    :to="formatLink('/account')"
                    class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                    tabindex="-1"
                  >
                    {{ $t('account.menu.myAccount') }}
                  </NuxtLink>
                  <button
                    id="user-menu-item-2"
                    class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                    tabindex="-2"
                    @click="invokeLogout"
                  >
                    {{ $t('account.menu.signOut') }}
                  </button>
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </ClientOnly>
    </div>
    <!-- <a
    href="#"
    class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
  >
    Sign up
  </a> -->
  </div>
</template>
